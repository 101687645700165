import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { Link, useLocation } from 'react-router-dom';

const ResponsiveNavbar = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const NavLinkItem = ({ to, className, children }) => {
        const isActiveLink = (location.pathname === to);

        return (
            <Nav.Link as={Link} to={to}
                className={`${className} ${isActiveLink ? 'active' : ''}`}
                onClick={() => setMenuOpen(false)}>
                {children}
            </Nav.Link>
        );
    };


    return (
        <Navbar expanded={isMenuOpen} bg="dark" variant="dark" expand="lg">
            <Link className="navbar-brand-link" to="/">
                <Navbar.Brand className="navbar-brand" onClick={() => setMenuOpen(false)}>
                    Home
                </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={toggleMenu} />
            <Navbar.Collapse id="responsive-navbar-nav" className={isMenuOpen ? 'show' : ''}>
                <Nav className="ms-auto">
                    <NavLinkItem to="/groceryFlyerSearch" className="grocery-flyer-search">
                        Grocery Flyer Search
                    </NavLinkItem>
                    <NavLinkItem to="/systemDesignDiagram">
                        System Design Diagram
                    </NavLinkItem>
                    <NavLinkItem to="/resume">
                        Resume
                    </NavLinkItem>
                </Nav>
            </Navbar.Collapse>

        </Navbar>
    );
};

export default ResponsiveNavbar;


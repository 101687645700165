import React, { useEffect, useRef } from 'react';
import './index.css';
import ProductsItemList from '../../components/ProductsItemList';

import 'bootstrap/dist/css/bootstrap.min.css';
import GroceryLogo from '../../components/GroceryLogo';
import { GroceryPageBanner } from '../../components/Banner';

import { connect } from 'react-redux';
import { fetchData, updateSearchResults } from '../../redux/actions/groceryFlyerSearch';

import DOMPurify from 'dompurify';



function GroceryFlyerSearch({
  loading,
  error,
  lastUpdate,
  walmartjson,
  costcojson,
  tntjson,
  walmartSearchResults,
  costcoSearchResults,
  tntSearchResults,
  fetchData,
  updateSearchResults,
}) {
  const searchTermRef = useRef(null);


  useEffect(() => {

    // Only fetch data if any of the JSON arrays are empty
    if (walmartjson.length === 0 || costcojson.length === 0 || tntjson.length === 0) {

      fetchData();
    }
  }, []);

  const handleInputChange = (event) =>  {
    const sanitizedValue = DOMPurify.sanitize(event.target.value);
    setTimeout(() => {
      if (sanitizedValue === '') {
        updateSearchResults('');
      } else {
        updateSearchResults(sanitizedValue); // Update with sanitized value immediately
      }
    }, 1000);
  };

  const handleSearch = () => {
    const searchTerm = searchTermRef.current.value.trim().toLowerCase();

    updateSearchResults(searchTerm);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
      searchTermRef.current.blur();
    }
  };


  const searchTermNotEmpty = searchTermRef.current && searchTermRef.current.value !== '';

  return (
    <div>
      <GroceryPageBanner
        topText={'Vancouver Grocery Flyer Search'}
        bottomText={'Save money every day!'}
        date={lastUpdate}
      />
      <div className="col-lg-11 d-flex flex-column flex-sm-row justify-content-start mt-sm-4 ms-sm-5 m-3 mx-auto">
        <input
          ref={searchTermRef}
          className="col-12 col-sm-6 col-md-4 p-2 m-1 mb-xs-0 input-text"
          type="text"
          placeholder="What are you looking for?"
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
        />
        <button className="col-12 m-1 btn btn-primary btn-fixed-size" onClick={handleSearch}>
          Search
        </button>
      </div>
      <div className="search-bar-border-bottom"></div>

      {loading ? (
        <div className="text-center mt-5">
          {error ? (
            <div>The server is under maintenance. Please try again later.</div>
          ) : (
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div>
            <GroceryLogo imagePath="/images/walmart-logo.png" text="Walmart" />
            <ProductsItemList data={searchTermNotEmpty ? walmartSearchResults : walmartjson} />
          </div>
          <div>
            <GroceryLogo imagePath="/images/costco-logo.avif" text="Costco" />
            <ProductsItemList data={searchTermNotEmpty ? costcoSearchResults : costcojson} />
          </div>
          <div>
            <GroceryLogo imagePath="/images/tnt-logo.avif" text="T&T" imageColor="green" />
            <ProductsItemList data={searchTermNotEmpty ? tntSearchResults : tntjson} />
          </div>
        </div>
      )}

      <div className="grocery-flyer-search-footer">
        * The web scraping results are based on my location and may vary in different regions.
      </div>
    </div>
  );
}



const mapStateToProps = (state) => ({
  loading: state.products.loading,
  error: state.products.error,
  lastUpdate: state.products.lastUpdate,
  walmartjson: state.products.walmartjson,
  costcojson: state.products.costcojson,
  tntjson: state.products.tntjson,
  walmartSearchResults: state.products.walmartSearchResults,
  costcoSearchResults: state.products.costcoSearchResults,
  tntSearchResults: state.products.tntSearchResults,
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: () => dispatch(fetchData()),
  updateSearchResults: (searchTerm) => dispatch(updateSearchResults(searchTerm)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroceryFlyerSearch);